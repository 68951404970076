import * as React from "react";
import { Link } from "gatsby-theme-material-ui";
import { StaticImage } from "gatsby-plugin-image";
import Box from '@mui/material/Box';
import Layout from "../components/layout";
import Seo from "../components/seo";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ChartLayout from "../components/ChartStructure"
import LifeNumber from "../components/LifeNumber"
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react'
import DatePicker from '../components/DatePicker'
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { NumberChip as Chip } from '../components/Chip';
import NumberConsole from "../components/NumberConsole"
import Button from '@mui/material/Button';
import MobileNumber from "../components/MobileNumber"
import Parse from '../vendor/Parse'
import CircularProgress from '@mui/material/CircularProgress';
import ThreeCol from '../components/ThreeColLayout'
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';

const { useEffect } = React;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const IndexPage = () => {
  const [number, setNumber] = useState('')
  const [day, setDate] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')
  const [elements, setElements] = useState(null)
  const [accordion, setAccordion] = useState([])
  const [result, setResult] = useState([])
  const [loading, setLoading] = useState(false)
  const [numberDisplay, setNumberDisplay] = useState(null)
  const [qc, setQC] = useState(0)
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const searchNumbers = async (query) => {
    return Parse.Cloud.run('queryNumbers', {
      query
    })
  }

  const getQueryCount = async (query) => {
    return Parse.Cloud.run('queryCount', {
      query
    })
  }

  const querySpecificNumber = async (query) => {
    return Parse.Cloud.run('querySpecificNumber', {
      _id: query
    })
  }

  useEffect(async () => {
    if (!isNaN(number)) {
      searchNumbers(number)
    }
    const qc = await getQueryCount();
    setQC(qc)
  }, [])

  useEffect(() => {

  }, [])


  return (
    <Layout>
    <Grid item xs={12} lg={6}>   
      <Seo title="Home" />

      <Box display="flex" justifyContent="center" flexDirection="column">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ textAlign: 'center' }} justifyContent="center">

            <h2>
              <a style={{ color: '#25D366', textDecoration: 'none' }} href={`https://api.whatsapp.com/send?phone=6587413159&text=I want to see the full number`}>Whatsapp</a> us to find out how to see the full number.
            </h2>

          </Box>
          <Box display="flex" justifyContent="center" flexDirection="row">
            <TextField id="outlined-basic" label="3 - 5 digits" variant="outlined"
              onChange={e => setNumber(e.target.value)}
            />
            <Button
              onClick={async () => {
                setLoading(true)
                const _result = await searchNumbers(number)
                setResult(_result)
                setLoading(false)

              }}
            >Find</Button>
          </Box>

        </LocalizationProvider>

      </Box>
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection="column">
        <h2>
          {
            `${qc} query left for this month. `
          }
        </h2>
        {

          !!result.length && <h2>{`${result.length} number Found. `}</h2>
        }

      </Box>
      <br />

      <NumberConsole
        displaySource={false}
        isLoading={loading}
        hideDl={true}
        dataArr={result}
        hideCopy={true}
        handleClick={async (n) => {
          const result = await querySpecificNumber(n._id)
          if (result?.answer) {
            setNumberDisplay({
              title: result?.answer?.num,
              content: <h2>
                The number can be found from { result?.answer?.sourceId }
              </h2>
            })
          } else {
            setNumberDisplay({
              title: n.num,
              content: <h2>
                <a style={{ color: '#25D366', textDecoration: 'none' }} href={`https://api.whatsapp.com/send?phone=6587413159&text=I want to see the full number`}>Whatsapp</a> us to find out how to see the full number.
              </h2>
            })
          }

          handleOpen()
        }}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {numberDisplay?.title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {
              numberDisplay?.content
            }
          </Typography>
        </Box>
      </Modal>
    </Grid>
    </Layout>
  )
}

export default IndexPage
